.player {
	position: relative;
	width: 100%;
	height: 100%;

	&-map {
		width: 100%;
		&-information-open{
			height: 65%;
		}
		&-information-closed{
			height: 100%;
		}
	}
	
	&-footer {
        bottom: 3rem;
        position: absolute;
        left: 0.4rem;
        z-index: 1000;
        color: white;
        padding: 1rem;
        width: 95%;
    }
}

.thermometerContainer {
	margin-top: 5px;
	.thermometer {
		width: 50px;
		margin: auto;
		.thermometer__percent-current {
			display: none;
		}
		.thermometer__bg-color {
			background: linear-gradient(#f44336, #8bc34a, #2196f3);
		}
	}
	.thermometer--small {
		.thermometer__draw-a:after {
			bottom: -10px;
		}
		.thermometer__draw-b::before {
			background: #2196f3;
		}
		.thermometer__draw-b:after {
			width: 20px;
			height: 20px;
			background: #2196f3;
		}
	}
}

.gadgets {
	text-align: center;
	.gadgetIcon {
		font-size: 15px;
		&.center {
			font-size: 45px;
		}
		&.batteyVoltage {
			font-size: 35px;
			color: #1890ff;
		}
	}
	.low {
		color: #ed1b24;
	}
	.med {
		color: #faad14;
	}
	.high {
		color: #1890ff;
	}
	.icon-battery-0,
	.zero {
		display: none;
	}
}
.player-speed-indicator {
	display: flex;
	align-items: center;	
  }

  .indicator-Player-Text {
	color:#108ee9
  }
  
  .hide-devices-info {
	height: 33%;
	display: block;
  }
  
  .hide-devices-info-hidden {
	height: 0%;
	display: none;
  }
  
  .tabs-container {
	padding-left: 20px;
	padding-right: 10px;
  }

.control-panel-player {
	width: 25.7rem;
	border-width: 10px;
	border-color: black;
	border-radius: 4px;
	position: absolute;
	top: 0.4rem;
	left: 0.4rem;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	background: #ffffff;
	box-shadow: $panel-shadow;
}

.left-panel {
    &-navbar {
		top: 1rem;
		position: absolute;
		right: 1rem;
    }
}

.deviceInformation {
	background: #fff;
	.table {
		height: 100%;
	}
	.ant-table-content {
		overflow-y: auto;
		height: calc(40vh - 160.5px);
		margin: 0 !important;
	}
	.ant-table-row {
		cursor: pointer;
	}
	.poiToggle {
		width: 200px;
		height: 20px;
		z-index: 999;
		font-size: 12px;
		margin-bottom: 10px;
		display: 'inline-block';
		position: absolute;
		transform: translateX(-50%);
		left: 50%;
		margin: 0 auto;
		right: calc(50vw - 70px);
		text-align: center;
		background: white;
		font-weight: bold;
		cursor: pointer;
		border: 1px solid #108ee9;
		box-shadow: 0px 2px 6px grey;
		.arrowToggle {
			margin-left: 3px;
		}

		&.hidden {
			bottom: 2%;
		  }
		
		&.visible {
			bottom: 36%;
		  }
	}
}

.playerDivider {
	display: flex !important;
    margin: 0px 0 !important;
    color: rgba(0, 0, 0, 0.85) !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    white-space: nowrap !important;
    text-align: center !important;
    border-top: 0 !important;
    border-top-color: rgba(0, 0, 0, 0.06) !important;
}
  
.playerMapCardTitle {
	font-size: 12px;
	display: inline-block;
	text-indent: 0%;
	width: 100%; 
	white-space: nowrap; 
	overflow: hidden;
	text-overflow: ellipsis;
  }
  
  .playerMapCardContainer {
	width:  100%;
	height: 90px;
	aspect-ratio: 2;
	text-align: center;
	border-radius: 8px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	border-bottom: none;
  
	.playerCardContent {
	  color: white;
	  font-size: 18px;
	  max-height: 15px;
	}
  }
  
  .playerMapIconCard {
	font-size: 25px;
	color: #2E2E2E;
  
	&--primary {
	  color: #FFFFFF;
	}
  
	&--success {
	  color: #388E3C;
	}
  
	&--warning {
	  color: #F39C12;
	}
  
	&--danger {
	  color: #A52019;
	}
  }
  
  .playerMapTextIcon {
	font-size: 12px;  
	color: #2E2E2E;
	font-weight: bold;  
  }
  
  .playerMapTextIcon--primary {
	color: #FFFFFF;
  }
  
  .playerMapTextIcon--success {
	color: #388E3C;
  }
  
  .playerMapTextIcon--warning {
	color: #F39C12;
  }
  
  .playerMapTextIcon--danger {
	color: #A52019;
  }
  .List-canbus {
	padding-bottom: 10px;
	max-height: 200px;
	padding-right: 25px;
	overflow-y: auto;
	
  }

  .panel-left {
	border-radius: 3px;
	padding-top: 4px;
	display: flex;
	flex-direction: column;

	.tab-button-left {
		z-index: 10;
		height: 68px;
		width: 25px;
		position: absolute;
		top: 42%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #ffffff;
		box-shadow: -4px 0px 0px rgb(0 0 0 / 15%);
		border-radius: 6px 0px 0px 6px;
		border-right: none;
		.collapse-icon-left { 
			font-size: 18px; 
		} 
	}

	.tab-button-left.collapsed {
		animation: slide-out-Tab-left 0.9s;
		animation-fill-mode: forwards;
		left: -18px;
	}

	.tab-button-left.expanded {
		animation: slide-in-Tab-left 1s;
		animation-fill-mode: forwards;
		left: -25px; 
		
	}

	.inner-content-left {
		padding: 15px 18px;
		overflow: hidden;
		position: relative;
		background: #ffffff;
		border-radius: 6px;
		margin-left: 10px;
	}

	.inner-content-left.collapsed {
		animation: slide-in-panel-left 1s;
		animation-fill-mode: forwards;
	}

	.inner-content-left.expanded {
		animation: slide-out-panel-left 1s;
		animation-fill-mode: forwards;
		right: 10px;
	}
}

@keyframes slide-out-Tab-left {
	from {
		left: 335px;
	}
	to {
		left: -5px;
	}
}

@keyframes slide-in-Tab-left {
	from {
		left: -5px;
	}
	to {
		left: 335px; 
	}
}

@keyframes slide-out-panel-left {
	from {
		left: 350px; 
	}
	to {
		left: 10px;
	}
}

@keyframes slide-in-panel-left {
	from {
		left: 100px;
	}
	to {
		left: 350px;
	}
}

.sensors-panel {
    width: 18.5rem;
    border-width: 1px;
    border-color: black;
	display: flex;
    flex-direction: column;
    gap: 0.5rem;
	margin-top: 2px;
	max-height: 38rem;
	overflow-y: auto;
	padding-right: 10px;
    padding-left: 10px;
	padding-bottom: 10px;

}

.event-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	max-height: 150px;
	overflow-y: 'auto'
}

.event-icon-container {
	height: 100%;
	width: 100%;
	display: flow;
}


.frequent-points {    
	position: relative;
	width: 100%;
	height: 100%;

	&-map {
		height: 700px;
		width: 100%;
	}
	&-navbar {
		top: 1rem;
		position: absolute;
		left: 0.4rem;
    }
}

.control-panel {
	width: 20rem;
	border-width: 4px;
	border-color: black;
	border-radius: 2px;
	position: absolute;
	top: 0.1rem;
	left: 0.1rem;
	padding: 0.8rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	background: #ffffff;
	box-shadow: $panel-shadow;
	font-size: 0.875rem;
}

.control-panel .ant-form-item {
	margin-bottom: 0.5rem; 
}

.control-panel .ant-input-number, .control-panel .ant-picker {
	width: 100%;
}

.control-panel .ant-btn {
	padding: 0.25rem 0.5rem;
	width: 100%;
}

.list-item-icon {
	color: #E41E2E;
	size: 25px;
}

.point-markers {
    background-color: #000000a8;
    color: #ffffff !important;
    position: absolute;
    padding: 0 4px;
    bottom: 0px;
    transform: translate(-50%, -50%);
}

.card {
    width: 100%;
    height: 100%;
}

.listContainer {
    max-height: 750px;
    overflow-y: auto;
    padding-right: 10px;
}


